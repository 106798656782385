.section-wrapper {
  position: relative;
}

/* .section-wrapper > section {
  min-height: 100%;
} */

.section-wrapper>section>div {
  margin: 0 auto;
  width: 70%;
}

.section-wrapper>section>div>h2 {
 /* margin-bottom: 50px;*/
}

.section-wrapper>section>div>h6 {
  margin-bottom: 40px;
 }

.section-wrapper section:not(:first-child) {
  /* min-height: 100vh; */
  display: flex;
}

.ProgressBar {
  position: sticky;
  top: 0px;
  z-index: 1000;
  background-color: white;
}

.selected-file {
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.175);
  border-radius: 0.375rem;
  margin-bottom: 5px;
}

.selected-file span {
  display: inline-block;
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}