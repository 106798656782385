.project-item {
  padding: 1rem 2rem;
  border: 1px solid rgba(0, 0, 0, 0.175);
  border-radius: 0.375rem;
}

@media only screen and (min-width: 699px) {
  .textarea-placeholder-with-error {
    position: absolute;
    font-size: 10px;
    bottom: 85px;
    right: 25px;
    color: #6c757d;
    pointer-events: none;
  }

  .textarea-placeholder-without-error {
    position: absolute;
    font-size: 10px;
    bottom: 15px;
    right: 25px;
    color: #6c757d;
    pointer-events: none;
  }
}

.min-col-width {
  min-width: 83px;
}

.select-project-file {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.prompt-delete-button {
  color: #fff;
}

.selected-file Button {
  margin-left: 10px;
  width: 40px;
}
