.submit-btn-sign-up {
  padding: 11px 32px;
  background: #14a077;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  color: #fff;
  text-transform: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  margin-top: 13px;
  border-style: none;
}
