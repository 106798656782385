.selected-languages-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  gap: 15px;
  margin-bottom: 50px;
}

.main-languages-container form {
  margin-bottom: 50px;
}

.portfolio-lang-card {
  width: 290px;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 10px;

  border: 1px solid rgba(18, 20, 23, 0.05);
  border-radius: 0.5rem;
  box-shadow: 0px 4px 36px 0px rgba(0, 0, 0, 0.02);
}

.lang-card-height {
  height: 60px;
}

@media (max-width: 400px) {
  .lang-text {
    font-size: 14pt !important;
  }
}
