.optionWrapper {
  position: absolute;
  width: 300px;
  display: none;
  z-index: 200;

  border: 1px solid rgba(18, 20, 23, 0.05);
  border-radius: 0.5rem;
  box-shadow: 0px 4px 36px 0px rgba(0, 0, 0, 0.02);
  background: rgb(255, 255, 255);
}

.optionWrapper div {
  max-width: calc(100% - 18px);
  overflow-y: auto;
  width: 100%;
}

.optionWrapper div button {
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 14px 18px;
  transition: background-color 0.3s ease;
  font-size: 12pt;
}

.optionWrapper button:active,
.optionWrapper button:focus,
.optionWrapper button:hover {
  background-color: rgba(18, 20, 23, 0.05);
}

.optionWrapper.open {
  display: flex;
  max-height: 300px;
  overflow: auto;
  min-width: 300px;
  max-width: 500px;

  padding: 20px;
}
