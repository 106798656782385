.buttons-layout {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.buttons-layout > button {
  width: 109px;
}

.responsive-btn .small-text {
  display: none; /* Hide the '<' symbol by default */
}

.responsive-btn .full-text {
  display: inline; /* Show the "Previous" text by default */
}

@media (max-width: 425px) {
  .buttons-layout > button {
    width: 75px;
  }

  .responsive-btn .small-text {
    display: inline; 
  }

  .responsive-btn .full-text {
    display: none; 
  }
}