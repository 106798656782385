/* Login.css */
.reset-main-wrapper {
  display: flex;
  justify-content: center;
  align-items: start;
  min-height: 100vh;
  background: url('../../images/login-page-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2rem;
}

.reset-form {
  display: flex;
  margin: 0 auto;
  background: white;
  padding: 2rem;
  border-radius: 10px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 450px;
}

.success-wrapper {
  margin: 0 auto;
  background: white;
  border-radius: 10px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 450px;
}

@media (max-width: 500px) {
  .reset-form {
    width: 100%;
    padding: 1rem;
  }
}

.reset-form label {
  margin-bottom: 0.5rem;
  width: 100%;
  text-align: left;
}

.reset-form input {
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
}

.reset-form-button {
  padding: 0.5rem 2rem;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: #14a077;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.reset-form p {
  color: red;
}

.container {
  padding: 20px;
}

.form-group label {
  font-family: 'Plus Jakarta Sans', sans-serif;
  margin-bottom: 10px;
}

.form-control {
  margin-bottom: 10px;
}

.submit-btn-reset {
  padding: 11px 32px;
  background: #14a077;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  color: #fff;
  text-transform: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  margin-top: 13px;
  border-style: none;
}