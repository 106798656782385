.certification-item {
  padding: 2rem 2rem;
  border: 1px solid rgba(0, 0, 0, 0.175);
  border-radius: 0.375rem;
}

.prompt-delete-button {
  color: #fff;
}

.selected-file Button {
  margin-left: 10px;
  width: 40px;
}
