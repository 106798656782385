/* Login.css */

.login-main-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../../images/login-page-bg-new.png') no-repeat center center;
  background-size: cover;
  z-index: 0;
  background-position-y: top;
  background-color: rgba(0, 0, 0, 0.9); /* Darker overlay */
  opacity: 0.9;
}

.login-main-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Another dark overlay */
  z-index: 1; /* Ensure it sits on top of the previous overlay */
}

.login-main-wrapper {
  display: flex;
  justify-content: flex-end; /* Aligns the form towards the right */
  align-items: center;
  min-height: 100vh;
  padding-right: 10%; /* Adjust padding to position form as desired */
  position: relative;
}

.login-form {
  width: 600px;
  max-width: 100%;
  padding: 2rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 2;
}

.logo-img {
  display: block;
  margin: 0 auto 2rem auto;
  width: 150px; /* Maintain logo size */
}

.card-title {
  font-family: 'Plus Jakarta Sans', sans-serif;
  text-align: left;
  margin-bottom: 1.5rem;
  font-size: 40px;
  font-weight: bold;
}

.form-group label {
  font-family: 'Plus Jakarta Sans', sans-serif;
  margin-bottom: 0.5rem;
  text-align: left;
  display: block;
  width: 100%;
}

.form-control {
  margin-bottom: 1rem;
  padding: 0.75rem;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 100%;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.submit-btn-login {
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: #000; /* Black button */
  width: 100%;
  box-sizing: border-box;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}

.divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  height: 1px;
  background: #e8ebf1;
}

.divider span {
  padding: 0 1rem;
  color: #727176;
}

.login-to-register-btn {
  background: none;
  border: none;
  color: #14a077;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  margin-right: 10px;
}

.btn-box {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}


@media (max-width: 768px) {

  .login-main-wrapper {
    justify-content: center; /* Centers the form on smaller screens */
    padding-right: 0;
  }

  .login-form {
    width: 90%; /* Wider form on medium screens */
    padding: 1.5rem; /* Adjust padding to avoid squishing */
  }
  .login-main-wrapper::before {
    background-position: center center; /* Adjust image position for smaller screens */
  }

  .card-title {
    font-size: 30px;
  }
}

@media (max-width: 480px) {
  .login-main-wrapper::before {
    background-size: cover; /* On very small screens, show the whole image */
    background-position: center center; /* Center the image */
  }

  .login-form {
    width: 100%; /* Full width on small screens */
    padding: 1rem; /* Reduce padding for small screens */
  }

  .card-title {
    font-size: 30px;
  }

}

@media (min-width: 1440px) {
  .login-form {
    width: 700px; /* Increase the width on larger screens */
  }

  .card-title {
    font-size: 30px;
  }
}

@media (min-width: 2560px) {
  .login-form {
    width: 800px; /* Even wider form on ultra-large screens */
    margin-left: 42%; 
    padding: 3em;
  }
  .login-main-wrapper {
    padding-right: 10%;
  }

  .login-form input, 
  .login-form label, 
  .login-form-button,
  .login-to-register-btn {
    font-size: 1.5rem; /* Increase the size of form elements */
  }

  .login-form input {
    padding: 1rem; /* Increase padding inside inputs */
  }

  .login-form-button {
    padding: 1rem 3rem; /* Increase button size */
  }

  .logo-img {
    width: 200px; /* Increase logo size for larger screens */
  }
}
