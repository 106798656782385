.links-toggle-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.links-toggle-button-wrapper > button {
  border: none;
  color: #0d6efd;
  background-color: transparent;
  display: flex;
  justify-content: center;
}

.links-add-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.links-add-btn-wrapper > button {
  border: none;
  color: #0d6efd;
  background-color: transparent;
  display: flex;
  justify-content: center;
}
