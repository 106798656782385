.prompt-tab-container {
  padding: 9px 10px 11px 12px;
  width: -moz-fit-content;
  background: #edf0f4;
  border-radius: 8px;
}
.prompt-tab-container:not(:last-child) {
  margin-bottom: 8px;
}
.prompt-tab-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}
.prompt-tab-text {
  width: 100%;
  margin: 0;
}
.prompt-tab-button-container {
  text-decoration: none;
  color: white;
  display: flex;
}

/* .prompt-tab-button-container:hover {
} */

.prompt-tab-button {
  background-color: #629c44 !important;
  border: none;
  color: white;
}

.prompt-tab-button:hover {
  background-color: #78bf53;
}

.prompt-tab-button:not(:last-child) {
  margin-right: 6px;
}

.position-relative {
  position: relative;
}

.titleWidth {
  max-width: 100%;
}

.tab-wrapper {
  position: relative;
}

.plus-icon-wrapper {
  position: relative;
  top: 50%; /* Adjust positioning as needed */
  right: 10px; /* Adjust this value to align the icon next to the tab */
  transform: translateY(-50%);
  cursor: pointer;
}

@media (min-width: 768px) {

  .sticky-col {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0; 
    height: 2000px; /* Ensure the sticky element has a manageable height */
    /* Add this to ensure sticky behavior works in a scrollable container */
    max-height: 100vh; 
  }
  }


