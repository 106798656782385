/* FileUpload.css */
.upload-area {
  border: 2px solid #ddd;
  padding: 40px;
  cursor: pointer;
}

.upload-area:hover {
  background-color: #f8f9fa;
}

.upload-area p {
  margin: 0;
  line-height: 1.5;
  color: #666;
}

.btn-complete {
  font-weight: 700;
  font-size: 16px;
  border-radius: 4px;
  background-color: #14a077;
}

.icon-download:before {
  content: '\e9bc';
}

.text-muted {
  font-size: 0.875rem;
  color: #6c757d;
  margin-top: 10px;
}


/* Sleeker file upload area */
.file-upload .upload-area {
  border: 1px solid #e0e0e0;
  padding: 20px;
  cursor: pointer;
  border-radius: 8px;
  background-color: #f4f4f4;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.file-upload .upload-area:hover {
  background-color: #e9ecef;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.file-upload .upload-area p {
  margin: 10px 0 0 0;
  font-size: 1rem;
  color: #6c757d;
}

.icon-upload {
  color: #6c757d;
  margin-bottom: 10px;
}

.file-upload-form {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  
  /* Default width for larger screens */
  max-width: 800px; /* Adjust this value as needed */
  
  /* Adjust vertical gap between content */
  row-gap: 10px; /* Reduced gap between rows */
  
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  
  /* Center content horizontally */
  justify-items: center;

  /* Increase content height proportionally */
  height: auto; /* Let the height adapt to content */
}

.uploaded-image {
  width: 100%;
  height: auto;
  max-width: 150px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.change-image-indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  opacity: 0;
  border-radius: 8px;
  transition: opacity 0.3s ease;
}

.position-relative:hover .change-image-indicator {
  opacity: 1;
}

.btn-complete {
  background-color: #28a745;
  border-color: #28a745;
  padding: 10px 20px;
  border-radius: 5px;
}

.btn-complete:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

.file-upload {
  max-width: 400px; /* Set a maximum width */
  margin: 0 auto;   /* Center it horizontally */
}



.modal-sub-title {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 700;
  font-size: 14px;
}

.modal-skills-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-content: center;
  gap: 5px;
}

.modal-skill-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #adb5bd;
  border-color: #6c757d;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 36px 0px rgba(0, 0, 0, 0.02);
  padding: 5px 5px 5px 10px;
}


.modal-skills-cross {
  width: 15px;
  height: 15px;
  scale: 1;
}

.modal-skills-cross:hover,
.modal-skills-cross:focus {
  scale: 1.2;
}

.modal-skills-cross-btn {
  background-color: transparent;
  border: none;
}

.doll-sign {
  position: absolute;
  top: 7px;
  left: 10px;
}

@media (max-width: 1024px) {
  .file-upload-form {
      max-width: 700px; /* Slightly reduce width for tablets and smaller devices */
  }
}

@media (max-width: 768px) {
  .file-upload-form {
      max-width: 90%; /* Use a percentage to make it more fluid on small screens */
      padding: 20px;  /* Adjust padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .file-upload-form {
      max-width: 95%; /* Keep it almost full width on mobile */
      padding: 15px;  /* Reduce padding on mobile */
  }
}

@media (max-width: 320px) {
  .file-upload-form {
    padding: 10px; /* Further reduce padding */
  }

  .upload-area {
    padding: 15px; /* Further reduce padding for the upload area */
  }

  .uploaded-image {
    max-width: 100%; /* Ensure the image fits within the screen width */
    height: auto;
    margin-bottom: 8px;
  }

  .change-image-indicator {
    bottom: 2px;
    font-size: 0.65rem; /* Even smaller font size for very small screens */
    padding: 2px 4px;
  }

  .text-muted {
    font-size: 0.65rem; /* Smaller font size for text */
    margin-top: 4px;
  }

  .btn-complete {
    width: 100%; /* Full-width button */
    font-size: 12px; /* Smaller font size */
    padding: 0.4rem 0.8rem;
  }

  .prompt-spinner {
    margin-top: 1rem;
    text-align: center;
  }

  /* Make sure all form controls are full width */
  .form-control, .form-select {
    width: 100%;
  }
}