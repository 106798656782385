.toggle-project-div {
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
}

.toggle-project-div > button {
  border: 2px solid green;
  color: green;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.toggle-project-div > button:hover {
  background-color: green;
  color: #fff;
}

.add-project-div {
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  justify-content: end;
}

.add-project-div > button {
  border: 2px solid green;
  color: green;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-top: 20px;
}

.add-project-div > button:hover {
  background-color: green;
  color: #fff;
}

.icons-size {
  width: 15px;
  height: 15px;
  margin: auto;
}

.underline-text {
  text-decoration: none;
}
