.header-container {
  width: 100%;
  background-color: #fff;
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
  height: 80px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

.login-button {
  margin-right: 5px;
}

.navigation-item {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #121212;
  text-decoration: none;
}
.navigation-item:not(:first-child) {
  margin-left: 26px;
}
.navigation-item:hover {
  color: #14a077;
}

.auth-button {
  border: 1px solid #14a077;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  border-radius: 4px;
  background-color: #14a077;
  color: white;
  padding: 12px;
}

.disabled-button {
  visibility: hidden;
}

.header-user-data-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.header-avatar {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: white;
  background-image: url('../../images/user-avatar.svg');
  background-size: 25px 25px;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid #14a077;
}

.user-settings-dropdown {
  display: flex;
  flex-direction: column;
  width: 150px;
  position: absolute;
  top: 80px;
  right: 0px;
  background-color: white;
  padding: 20px;
  z-index: 2000;
  border-color: #6c757d;
  border-radius: 0.5rem;
  box-shadow:
    0px 6px 36px 0px rgba(0, 0, 0, 0.1),
    0px 6px 36px 0px rgba(0, 0, 0, 0.15);
}

.user-settings-dropdown a,
.user-settings-dropdown button {
  width: 100%;
  padding: 5px;
  text-decoration: none;
  border: none;
  background-color: transparent;
  font-size: 20px;
  scale: 1;
  transition: scale 0.3s ease;
}

.user-settings-dropdown a:hover,
.user-settings-dropdown button:hover {
  scale: 1.2;
}

.dropdown-options:hover {
  background-color: #1573470f;
  border-radius: 8px;
}

.logo {
  margin-top: -5px;
}

@media (max-width: 320px) {
  .header-user-data-wrapper span{
    display: none;
  }
}