body {
  background-color: #f5f5f5 !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title-dash {
  padding: 30px 0;
  display: flex;
  align-items: center;
}

.title-dash::before {
  content: '';
  width: 8px;
  height: 32px;
  background-color: #14a077;
  margin-right: 8px;
  display: inline-block;
  border-radius: 100px;
}

.form-control:focus {
  border-color: #15734785 !important;
  box-shadow: 0 0 0 0.2rem #15734717 !important;
}

.form-select:focus {
  border-color: #15734785 !important;
  box-shadow: 0 0 0 0.2rem #15734717 !important;
}
