.main-layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .content-container {
    display: flex;
    flex: 1; /* Take up remaining space after header */
  }
  
  .main-content {
    flex: 1; /* Main content takes the remaining space */
    padding: 20px; /* Add padding or adjust as needed */
    overflow-y: auto; /* If needed, allow scrolling */
  }
  
  .sidebar {
    width: 250px; /* Sidebar width */
    background-color: #f4f4f4; /* Background color for sidebar */
    height: 100%; /* Sidebar takes up full height */
    position: sticky; /* Makes it stay visible while scrolling */
    top: 0; /* Sticks the sidebar at the top */
    padding: 20px; /* Add padding or adjust as needed */
  }
  