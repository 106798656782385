@media screen and (max-width: 425px) {
  .container {
    padding: 50px 19px !important;
  }

  .portfolio-card .card-body {
    padding: unset !important;
  }

  .accordion {
    padding: 0.3rem !important;
  }

  .info-section {
    display: block !important;
  }
}

@media screen and (width: 768px) {
  .tab-menu {
    flex-wrap: unset !important;
  }
}

/* Initially hide all menu containers */
/* Sidebar base style */
.sidebar-col {
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: sticky;
  top: 0;
  width: 80px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: width 0.3s ease; /* Smooth transition for width */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; 
}

/* Sidebar icon container */
.sidebar-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: auto;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

/* Icon default color and hover effect */
.sidebar-icon-container svg {
  color: #555;
  transition: color 0.3s ease;
}

.sidebar-icon-container:hover svg {
  color: #198754;
}

/* Menu items (initially hidden) */
.menu-items {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10px;
  margin-top: 10px;
  display: flex;
}

/* Show menu items when the icon is clicked */
.sidebar-icon-container.active .menu-items {
  max-height: 100px; /* Adjust the height based on the number of menu items */
}

.sidebar-col .sidebar-icon-container.active {
  width: 200px; /* Expand width based on your content */
}

/* Menu item style */
.menu-item {
  color: #333;
  cursor: pointer;
  padding: 5px 8px;
  font-size: 19px;
  width: 100%;
  transition: background-color 0.3s ease;
}

.menu-item:hover {
  background-color: #f1f1f1;
  color: #198754;
}

.menu-item:not(:last-child) {
  border-bottom: 1px solid #ddd; /* This creates the divider */
}

/* Spacing adjustments for icons */
.sidebar-icon-container + .sidebar-icon-container {
  margin-top: 20px;
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .sidebar-col {
    width: 60px;
  }

  .sidebar-icon-container {
    margin-bottom: 15px;
  }
}


.preview-top-wrapper {
  display: flex;
  gap: 60px;
}

.preview-top-row {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.preview-edit-btn {
  margin-left: auto;
}

.preview-credentials {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.preview-share-button {
  width: 158px;
  height: 50px;
}

.save-portfolio-button {
  width: auto;
}

.preview-avatar {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  background-color: white;
  background-image: url('../../images/user-avatar.svg');
  background-size: 150px 150px;
  background-repeat: no-repeat;
  background-position: bottom;
}

.preview-avatar-wrapper {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.preview-skill-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #adb5bd;
  border-color: #6c757d;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 36px 0px rgba(0, 0, 0, 0.02);
  padding: 5px;
  font-size: 12px;
}

.list-font span {
  font-size: 14px;
  color: #6c757d;
}

.vertical-divider {
  border-right: 1px solid #adb5bd;
  height: 100%;
}

.view-project-btn {
  background-color: transparent;
  color: #007bff;
  text-decoration: underline;
  border: none;
  padding: 0;
  text-align: center;
  display: inline-block;
  font-size: 1rem;
  cursor: pointer;
}

.view-project-btn:hover {
  color: #0056b3;
}

.preview-cards-ref-header {
  display: flex;

  justify-content: space-between;
  align-items: center;
}

/*.preview-info-row {
  display: flex;
  justify-content: space-between;
}*/

.preview-ref-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-content: center;
}

.preview-ref-content h5,
.preview-ref-content p {
  width: 100%;
  display: flex;

  justify-content: center;
  align-content: center;
}

.preview-cards-margin-bottom .card {
  margin-bottom: 20px;
}

.preview-spans {
  display: flex;
  justify-content: center;
  align-content: center;
  align-self: center;
  font-size: 13px;
  padding: 10px;
  color: white;
  font-weight: 500;
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 36px 0px rgba(0, 0, 0, 0.02);
}

.preview-span-green {
  background-color: #198754;
}

.preview-span-yellow {
  background-color: #ffd700;
}

.preview-span-orange {
  background-color: #ffa500;
}

.preview-span-red {
  background-color: #dc3545;
}

.preview-work-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.preview-colors-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 40px;
}

/*.user-info-edit-button {
  display: flex;
  justify-content: center;
  background-color: transparent;
  border: none;
  align-items: flex-start;
}*/

.user-overview-edit-btn {
  margin: auto;
  background-color: transparent;
  border: none;
}

.add-popup {
  position: absolute;
  left: -260px;
  top: 50px;
  width: 300px;
  z-index: 200;
}

@media screen and (min-width: 320px) {
  .user-popup {
    position: absolute;
    width: 300px;
    top: 45px;
    right: -400%;
    background-color: white;
    border-color: #6c757d;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 36px 0px rgba(0, 0, 0, 0.02);
    z-index: 100;
  }
}

@media screen and (min-width: 768px) {
  .user-popup {
    position: absolute;
    width: 300px;
    top: 45px;
    right: 0;
  }
}

@media screen and (min-width: 1120px) {
  .user-popup {
    position: absolute;
    width: 300px;
    top: 45px;
    left: 0;
  }
}

@media screen and (min-width: 375px) {
  .user-popup {
    width: 310px;
  }
}

/* User-left-popup card styling */
.user-left-popup {
  position: absolute;
  width: 400px;
  top: 0;
  right: 0;
  z-index: 200;
  background-color: #fff; /* Clean white background */
  border-radius: 10px; /* Rounded corners for a modern look */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.user-left-popup:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15); /* Hover effect for interaction */
}

/* Scrollable content in card */
.scrollable-card {
  overflow-y: auto; /* Scrollable if content exceeds height */
  padding: 20px;
}

/* Card title styling */
.user-left-popup .card-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 15px;
}

/* Form styling */
.user-left-popup .form-control {
  padding: 10px;
  border-radius: 5px; /* Rounded corners for input */
  border: 1px solid #ddd;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.user-left-popup .form-control:focus {
  border-color: #198754; /* Green border on focus */
  box-shadow: 0 0 0 0.2rem rgba(25, 135, 84, 0.25);
}

/* Radio buttons styling */
.user-left-popup .form-check-label {
  font-size: 14px;
  color: #333;
}

.user-left-popup .form-check-input {
  margin-right: 10px;
  accent-color: #198754; /* Green radio button */
}

/* Submit button */
.user-left-popup .btn-success {
  background-color: #198754;
  border-color: #198754;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  width: 100%;
  transition: background-color 0.3s ease;
}

.user-left-popup .btn-success:hover {
  background-color: #145a32;
}

/* Responsive styling */
@media (max-width: 768px) {
  .user-left-popup {
    width: 100%; /* Make it full width on smaller screens */
    top: 0;
    right: 0;
    border-radius: 0; /* Remove rounded corners */
  }

  .scrollable-card {
    max-height: 300px;
  }
}

@media (max-width: 576px) {
  .user-left-popup .card-title {
    font-size: 18px;
  }

  .user-left-popup .form-control {
    font-size: 13px;
  }

  .user-left-popup .form-check-label {
    font-size: 13px;
  }

  .user-left-popup .btn-success {
    font-size: 14px;
    padding: 8px 16px;
  }
}

.user-left-popup .optionWrapper.open {
  min-width: 100px;
  width: 100%;
}


@media screen and (min-width: 320px) {
  .user-lower-popup {
    position: absolute;
    width: 300px;
    bottom: 45px;
    right: -400%;
    background-color: white;
    border-color: #6c757d;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 36px 0px rgba(0, 0, 0, 0.02);
    z-index: 100;
  }
}

@media screen and (min-width: 768px) {
  .user-lower-popup {
    position: absolute;
    width: 300px;
    bottom: 45px;
    right: 0;
  }
}

@media screen and (min-width: 1120px) {
  .user-lower-popup {
    position: absolute;
    width: 300px;
    bottom: 45px;
    left: 0;
  }
}

@media screen and (min-width: 375px) {
  .user-popup {
    width: 310px;
  }
}

.scrollable-card {
  /*max-height: 400px;*/
  overflow-y: auto;
  overflow-x: hidden;
}

.file-upload-section {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.preview-work-wrapper .hovered .close-button {
  display: block;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.preview-work-wrapper .close-button {
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.preview-work-wrapper .hovered {
  position: relative;
  font-size: 15px;
}

.preview-work-wrapper .hovered .close-button {
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

/*.user-info-edit-button {
  background: none;
  border: none;
  cursor: pointer;
}*/

.titleConstraint {
  display: flex;
  flex-wrap: wrap;
  max-width: 350px;
}

.nav-item-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.delete-button {
  display: none;
  margin-left: 10px;
}

.nav-item-wrapper .delete-button {
  display: inline-block;
}

.custom-font {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 700;
}

.preview-font-weight {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.toast-message {
  background-color: #198754 !important;
  color: white !important;
  font-size: 1.2rem;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
}

.reference-message {
  border: 1px solid #adb5bd;
  margin: 0px auto;
  color: black;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  width: 346px;
}

/* Additions */

/* General Styling */
body {
  font-family: 'Inter', sans-serif;
  background-color: #f4f6f9;
  color: #333;
}

/* Information Card */
.sleek-card {
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.sleek-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

/* Info Section Styling */
.info-section {
  padding: 15px 0;
  border-bottom: 1px solid #e6e9f0;
  display: flex;
  justify-content: space-between;
}

.info-section:last-child {
  border-bottom: none;
}

.info-heading {
  font-weight: 600;
  font-size: 16px;
  color: #333;
  margin-bottom: 6px;
  letter-spacing: 0.5px;
}

.info-response {
  font-size: 15px;
  color: #666;
  margin-top: 5px;
  line-height: 1.4;
}

/* Link Styling */
.info-link {
  text-decoration: none;
  color: #1d72b8;
  transition: color 0.2s ease;
}

.info-link:hover {
  color: #0056b3;
}

/* Socials Section */
.socials-header {
  font-weight: bold;
  color: #6c757d;
  margin-bottom: 15px;
}

.socials-icon {
  display: inline-block;
  color: #000;
}

.socials-icon:hover {
  opacity: 0.8;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .sleek-card {
    padding: 15px;
  }

  .info-heading {
    font-size: 15px;
  }

  .info-response {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .sleek-card {
    padding: 12px;
  }

  .info-heading {
    font-size: 14px;
  }

  .info-response {
    font-size: 13px;
  }
}

@media (max-width: 375px) {
  .sleek-card {
    padding: 10px;
  }

  .info-heading {
    font-size: 13px;
  }

  .info-response {
    font-size: 12px;
  }
}

/*Profile image section */

/* Profile Card */
.profile-card {
  margin: 0 auto;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  width: 100%; 
}

/* Header (Colored Half with Profile Image) */
.profile-header {
  background: linear-gradient(to bottom, #e5f7f6 90%, #fff 10%);
  position: relative;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-image-wrapper {
  position: absolute;
  top: 76px; /* Push the profile image halfway down */
  transform: translateY(-50%);
}

.profile-avatar {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid white; /* Border around the image */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.default-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #ccc;
  border: 3px solid white;
}

/* Edit Button for Avatar */
.user-info-edit-button {
  position: absolute;
  bottom: -5px;
  right: -5px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  padding: 6px;
  border-radius: 5px;
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.reference-delete-button {
  bottom: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border: 1px solid #ddd;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  padding: 6px;
  border-radius: 5px;
  transition: background-color 0.2s ease, transform 0.2s ease;
}

/* Profile Body */
.profile-body {
  text-align: center;
  padding-top: 50px; /* Compensate for the overlap of the profile image */
}

.profile-name {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 5px;
  color: #333;
}

.profile-title {
  text-transform: uppercase;
  background-color: #f0f4f7;
  padding: 10px 15px; /* Add some padding for better spacing */
  font-size: 18px; /* Slightly smaller font compared to the name */
  color: #333; /* Dark text color */
  font-weight: 600; /* Bold to make it stand out */
  border-radius: 8px; /* Rounded corners */
  text-align: center; /* Centered text for symmetry */
  margin: 10px auto; 
  width: fit-content;
  display: inline-block;
}

/* Share Profile Button */
.share-profile-button {
  background-color: transparent;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  color: black;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  gap: 10px;
  display: inline-flex;
  margin: 0 auto; 
}

.share-button-container {
  text-align: center; 
}

.share-profile-button:hover {
  background-color: #157347;
}

.share-icon {
  background-size: 18px;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

@media (max-width: 768px) {
  .profile-avatar {
    width: 100px;
    height: 100px;
  }

  .profile-body {
    padding-top: 40px;
  }

  .profile-name {
    font-size: 20px;
  }

  .profile-title {
    font-size: 18px;
  }

  .share-profile-button {
    padding: 8px 16px;
    font-size: 13px;
  }
}

@media (max-width: 576px) {
  .profile-avatar {
    width: 80px;
    height: 80px;
  }

  .profile-name {
    font-size: 18px;
  }

  .profile-title {
    font-size: 16px;
  }

  .share-profile-button {
    padding: 6px 12px;
    font-size: 12px;
  }
}

/* Skills Section */
.skills-container {
  margin-top: 20px;
  display: inline-block;
  text-align: center;
}

.skill-badge + .user-info-edit-button {
  margin-left: 5px;
}

.skill-badge {
  background-color: #218838; /* Modern green color */
  border-radius: 20px; /* Softer round edges */
  padding: 6px 12px; /* Balanced padding */
  font-size: 14px; /* Adjust font size */
  color: #fff; /* White text */
  display: inline-block;
  font-weight: 500; /* Slightly bolder text */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Soft shadow */
  transition: all 0.3s ease; /* Smooth transition */
  cursor: pointer;
}

.skill-badge:hover {
  background-color: #1e7e34; /* Slightly darker on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
  transform: translateY(-2px); /* Lift effect on hover */
}

.skill-badge:active {
  background-color: #19692c; /* Darker green on click */
  transform: translateY(0); /* Reset the lift effect */
}

@media (max-width: 768px) {
  .skill-badge {
    font-size: 13px; /* Smaller font size for mobile */
    padding: 5px 10px; /* Adjust padding for mobile */
  }
}


/* Modal Styling */
.modal-header,
.modal-body,
.modal-footer {
  font-family: 'Inter', sans-serif;
}

.modal-footer .btn {
  font-weight: 700;
  font-size: 16px;
  border-radius: 4px;
}

/* Responsive adjustments */
@media (max-width: 768px) {

  .profile-avatar {
    width: 80px;
    height: 80px;
  }

  .profile-name {
    font-size: 20px;
  }

  .profile-title {
    font-size: 14px;
  }

  .share-profile-button {
    font-size: 13px;
  }
}

/*portfolio section */

.portfolio-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  border: none;
  transition: all 0.3s ease;
}

.portfolio-card:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.portfolio-section {
  margin-bottom: 2rem;
  position: relative;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem; /* Space between heading and content */
  border-bottom: 2px solid #e0e0e0; 
}

.section-title {
  font-size: 18pt !important;
  font-weight: bold;
  color: #333;
  padding-left: 0.5rem;
  margin-bottom: 0 !important;
}

.add-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.3s ease;
  margin-right: 10px;
}

.add-button:hover {
  color: #198754;
  transform: scale(1.05);
}

.project-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.item-title {
  font-size: 14pt !important;
  font-weight: bold;
  color: #333;
  margin-bottom: 0 !important;
}

.edit-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: #000;
  transition: color 0.3s ease;
  margin-bottom: 20px;
}

.edit-button:hover {
  color: #145a32; 
}

.portfolio-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.project-description {
  display: flex;
  margin-top: 1rem;
}

.vertical-divider-portfolio {
  width: 2px;
  background-color: #ddd;
  margin-right: 10px;
}

.description-text {
  display: grid;
}

.view-project-btn-po {
  font-size: 0.9rem;
  color: #198754;
  text-decoration: none;
  transition: color 0.3s ease;
  display: inline-block;
}

.view-project-btn-po:hover {
  color: #145a32; /* Darker green on hover */
  text-decoration: underline;
}

/* Accordion card background */
.accordion {
  padding: 0.5rem 1rem;
}

.portfolio-section .accordion-button:not(.collapsed),
.prompt-tab-accordion .accordion-button:not(.collapsed) {
  background-color: white;
}

.accordion-item {
  background:linear-gradient(to bottom, #e5f7f6 50%, #fff 50%);
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05); /* Slight shadow for modern look */
  padding: 8px 12px; /* Reduce padding inside the accordion for less height */
  min-height: 50px; 
}

/* Accordion header customization */
.accordion-header {
  font-size: 16px;
  background-color: transparent; /* Keep header transparent to match background */
  border: none;
  font-weight: bold;
  color: #333;
  padding: 6px 10px;
}

/* Accordion body customization */
.accordion-body {
  background-color: #fff;
  font-size: 14px;
  color: #555;
  padding: 8px 12px;
}

.accordion-button {
  padding: 1px 20px;
}

.prompt-tab-accordion .accordion-button {
  padding: 12px 16px !important;
}

/* For screens smaller than 768px (tablets and mobile) */
@media (max-width: 768px) {
  /* Adjust accordion item padding */
  .accordion-header {
    padding: 8px 12px;
    font-size: 14px;
  }

  .accordion-body {
    padding: 8px 12px;
    font-size: 12px;
  }

  /* Adjust the edit button and positioning */
  .edit-button {
    padding: 3px;
    margin-bottom: -3px; /* Move edit button slightly higher */
  }

  /* Adjust the item title font size for smaller screens */
  .item-title {
    font-size: 14pt !important;
  }

  /* Adjust the view project button size for mobile */
  .view-project-btn-po {
    font-size: 0.8rem;
  }

  /* Adjust the accordion card min-height */
  .accordion-item {
    min-height: 50px; /* Reduce height further for smaller screens */
  }
}

/* For extra small screens smaller than 576px (mobile devices) */
@media (max-width: 576px) {
  .accordion-header {
    padding: 6px 10px;
    font-size: 13px;
  }

  .accordion-body {
    padding: 6px 10px;
    font-size: 11px;
  }

  /* Reduce the item title size even further for small screens */
  .item-title {
    font-size: 13pt !important;
  }

  /* Reduce edit button padding */
  .edit-button {
    padding: 2px;
    margin-bottom: -5px; /* Move edit button higher */
  }

  /* Make the accordion item slightly shorter */
  .accordion-item {
    min-height: 45px;
  }
}

.tab-container-with-button {
  position: relative;
}

.tab-menu {
  display: flex;
  align-items: center;
}

.preview-share-button {
  position: absolute;
  right: 10px; /* Align to the right */
  top: -20px;   /* Align at the top */
  font-size: 10pt;
}

/* Responsive adjustments for tablets (max-width: 768px) */
@media (max-width: 768px) {
  .preview-share-button {
    right: 5px;   /* Adjust for tablet screens */
    top: -10px;   /* Adjust for tablet screens */
    font-size: 0.9rem; /* Reduce button size for tablets */
  }
}

/* Responsive adjustments for mobile screens (max-width: 576px) */
@media (max-width: 576px) {
  .preview-share-button {
    right: 0px;    /* Align button closer for mobile */
    top: -5px;     /* Adjust for mobile */
    font-size: 0.8rem; /* Further reduce button size for mobile */
  }
}

/* General Tab Menu Styling */
.tab-menu .nav-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  font-weight: bold;
  font-size: 1rem;
  color: #333;
  transition: background-color 0.3s ease;
}

/* Selected Tab with Green Font */
.active-tab .tab-title {
  color: #198754; /* Green font for selected tab */
}

.tab-menu .nav-item-wrapper:hover {
  background-color: #f8f9fa;
}

/* Portfolio Title */
.portfolio-title {
  margin-right: 10px;
}

/* Icon Buttons Wrapper */
.portfolio-actions {
  display: flex;
  gap: 10px;
  align-items: center;
}

/* Edit and Delete Button Styling */
.delete-button,
.add-button {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 5px;
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.user-info-edit-button:hover,
.delete-button:hover,
.add-button:hover {
  background-color: #f0f0f0;
  transform: scale(1.1);
}

/* Hover Effect for Tabs */
.tab-menu .nav-item-wrapper:hover {
  background-color: #f8f9fa;
}

/* Edit Icon */
.user-info-edit-button {
  color: #198754; /* Green for edit */
}

/* Delete Icon */
.delete-button {
  color: #dc3545; /* Red for delete */
}

/* Add Icon */
.add-button {
  color: #007bff; /* Blue for add */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .tab-menu .nav-item-wrapper {
    font-size: 0.9rem;
    padding: 8px 10px;
  }

  .user-info-edit-button,
  .delete-button,
  .add-button {
    padding: 4px;
    margin-right: 5px;
  }

  .portfolio-title {
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .tab-menu .nav-item-wrapper {
    font-size: 0.85rem;
    padding: 6px 8px;
  }

  .user-info-edit-button,
  .delete-button,
  .add-button {
    padding: 3px;
    margin-right: 3px;
  }

  .portfolio-title {
    font-size: 0.85rem;
  }
}

/* Accordion container */
.prompt-tab-accordion .accordion-item {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 15px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

/* Default buttons (Edit & Delete) */
.prompt-tab-default-buttons {
  margin-left: auto;
  display: flex;
  gap: 5px; /* Reduce gap between buttons */
  justify-content: flex-end;
}

/* Expanded buttons section */
.prompt-tab-expanded-section {
  margin-top: 15px;
}

/* Divider for expanded section */
.divider {
  border-top: 1px solid #e9ecef;
  margin-bottom: 10px;
}

/* Expanded buttons */
.prompt-tab-expanded-buttons {
  display: flex;
  justify-content: center;
  gap: 10px; 
}

.prompt-tab-button {
  background-color: #6c757d; /* A more subtle grey color */
  color: white;
  border: none;
  padding: 6px 8px; /* Reduced padding to make buttons smaller */
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.prompt-tab-button:hover {
  background-color: #5a6268;
}

.prompt-tab-accordion .delete-btn {
  background-color: #dc3545 !important;
}

.delete-btn:hover {
  background-color: #c82333;
}

.prompt-tab-text {
  font-size: 16px;
  color: #333;
  margin-bottom: 0; 
  max-width: 79%;
  padding-right: 15px;
  text-wrap: wrap;
}

.prompt-tab-expanded-buttons .prompt-tab-button {
  padding: 5px 10px;
  font-size: 13px; /* Smaller font size */
}

.prompt-tab-col {
  display: flex;
  justify-content: space-between; /* Space between text and buttons */
  align-items: center; /* Align vertically in the center */
}

/* For responsive design */
@media (max-width: 768px) {
  .prompt-tab-default-buttons {
    flex-direction: column;
  }

  .prompt-tab-expanded-buttons {
    flex-direction: column;
  }

  .prompt-tab-button {
    width: 100%;
    text-align: center;
  }
}

.overview-pd {
  padding: 0.5rem 1rem !important;
}

.copy-link-modal {
  display:block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  max-width: 438px;
  margin: 0 auto;
}

.copy-link-modal .modal-dialog-centered {
  width: 372px;
}

.copy-link-textbox {
  width: 100%;
  padding: 1.2rem !important;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: auto !important;
  color: #333;
  background-color: #f9f9f9;
}

/* Copy button icon */
.copy-button {
  background-color: #218838;
  border: none;
  color: white;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.copy-button:hover {
  background-color: #18692e;
}

/* Change Link button */
.change-link-button {
  margin-top: 10px;
  background-color: transparent;
  border: none;
  color: #218838;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.change-link-button:hover {
  background-color: #0056b3;
}


/* Text above link input */
.modal-instructions {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
  text-align: center;
}

.popup-card {
  width: 100%; /* Take full width */
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  overflow: auto;
  flex-grow: 1;
  position: relative;
  top: 20px; 
  right: auto;
}

.modal-dialog {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content towards the top */
  align-items: center;
  width: 100%;
  max-width: 100%; /* Prevent exceeding screen width */
  height: auto;
  margin: 0 auto;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

@media (max-width: 425px) {
  .modal-dialog {
    width: 100%;
    height: auto;
    margin: 0;
  }

  .popup-card {
    width: 90%;
    padding: 15px;
    margin: 0 auto;
    box-sizing: border-box;
    top: 10px; /* Reduce top margin slightly on smaller screens */
  }
}

@media (min-width: 992px) {
  .modal-dialog {
    max-width: 80%; /* Make modal wider on larger screens */
    height: auto;
  }
}

/* Ensure modal content isn't pushed to the bottom */
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Ensure content starts from the top */
  padding: 20px;
  width: 100%;
  max-width: 600px; /* Cap width for larger screens */
  max-height: 90vh; /* Prevent overflow vertically */
  overflow-y: auto; /* Allow vertical scrolling if content overflows */
  overflow-x: hidden; /* No horizontal scrolling */
}

@media (max-width: 768px) {
  .popup-card {
    width: 90%;
    margin: auto;
  }

  .selected-file {
    font-size: 14px;
  }

  .file-upload-section button,
  .file-upload-section .select-project-file {
    width: 100%;
  }
}

.form-group {
  margin-bottom: 1rem;
}

.textarea-placeholder {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 0.8rem;
  color: #888;
}

/* Ensure the file name is aligned properly */
.file-name {
  margin: 0;
  word-break: break-all; /* Prevent long names from overflowing */
  font-size: 14px;
}

.selected-file {
  display: flex;
  justify-content: space-between; /* Space between file name and delete button */
  align-items: center; /* Vertically center the file name and button */
  padding: 8px 0; /* Add some padding */
  border-bottom: 1px solid #e0e0e0; /* Optional divider */
  margin-bottom: 10px; 
}

.selected-file p {
  margin: 0;
  word-break: break-all;
}

/* Buttons and alerts spacing */
.button {
  width: 100%;
  margin-top: 10px;
}

.alert {
  margin-top: 10px;
}

/* Add padding between form elements */
.form-control {
  margin-bottom: 1rem;
}

.popup-textarea {
  height: 10vh !important;
}

/* Base styling for textarea */
.popup-textarea {
  width: 100% !important;
  padding: 10px !important;
  font-size: 1rem !important;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Default height for large screens */
.popup-textarea {
  height: calc(90vh - 500px) !important; /* Adjust the height based on the viewport */
}

/* For mobile screens (less than 768px) */
@media (max-width: 768px) {
  .popup-textarea {
    height: calc(100vh - 300px) !important; /* Adjust height for tablets */
  }
}

/* For smaller mobile screens (less than 425px) */
@media (max-width: 425px) {
  .popup-textarea {
    height: calc(100vh - 250px) !important; /* Adjust height for small mobile devices */
  }
}

/* General popup modal styling */
.popup-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%; /* Adjust based on the width you want */
  max-width: 600px; /* Cap the width on larger screens */
  background-color: white;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000; /* Ensure it's on top of other content */
  border-radius: 10px;
  overflow: hidden;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark background overlay */
  z-index: 999; /* Behind the popup modal */
}

@media (min-width: 768px) {

.sticky-col {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 80px; 
  height: auto; /* Ensure the sticky element has a manageable height */
  /* Add this to ensure sticky behavior works in a scrollable container */
  max-height: 100vh; 
}

.row-sticky .col-md-4 {
  height: auto; /* Add enough height to create scrollable area */
  padding: 10px;
}
}

.down-arrow{
  cursor: pointer;
  display: inline;
  padding: 6px;
  transition: background-color 0.2s ease, transform 0.2s ease;
  margin-top: 10px;
}

.toast-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1050; /* Ensure it's above other elements */
}

.g-10{
  gap:10px
}

.countdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto; /* Full viewport height */
  background-color: rgba(0, 0, 0, 0.85); /* Dark semi-transparent background */
}

.countdown-text {
  color: #ffffff;
  font-size: 1.4rem;
  font-family: 'Arial', sans-serif; /* Choose a clean, modern font */
  text-align: center; /* Center the text */
  padding: 20px;
  border: 2px solid #00ff00; /* Optional border to make it pop */
  border-radius: 10px; /* Slightly rounded corners */
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background for the text */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); /* Soft shadow for depth */
}