.prompt-header-container {
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.prompt-header-container .title-dash {
  padding: 8px 0;
}

.form-container {
  background-color: white;
  padding: 34px 60px 34px;
}

.prompt-text {
  font:
    16px / 26px 'Plus Jakarta Sans',
    sans-serif;
  font-weight: 400;
  color: #64666c;
  padding: 9px 10px 11px 12px;
  background: rgba(20, 129, 96, 0.1);
  border-radius: 8px;
  margin: 0;
}

.prompt-text-container {
  padding: 16px 11px;
}

.buttons-container {
  display: flex;
  justify-content: space-evenly;
}

.prompt-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #64666c;
}

.prompt-container:nth-last-child(2) {
  border-bottom: none;
}

.prompt-container:hover {
  background-color: #f5f5f5;
}

/*.prompt-modal-container-video {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
}*/


.btns-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.prompt-video-modal-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 100%;
}

.prompt-modal-text {
  font:
    16px / 26px 'Plus Jakarta Sans',
    sans-serif;
  font-weight: 400;
  color: #64666c;
  text-align: left;
  cursor: pointer;
  margin: 0;
}

.prompt-modal-text:hover {
  background-color: #f0f0f0;
}

.prompt-button:hover {
  z-index: 2;
  transform: translateY(-3px) scale(1.1);
}

.prompt-button:hover .prompt-edit-button {
  color: #14a077;
}

.prompt-button:hover .prompt-delete-button {
  color: #fff;
}

.edit-btn:hover {
  background-color: white !important;
}

.delete-btn:hover {
  background-color: #14a077 !important;
}

.prompt-button {
  padding: 0 !important;
  background-color: #f5f5f2 !important;
  width: 40px;
  height: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: none !important;
  color: black !important;
  transition: all 0.3s ease !important;
}

.prompt-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
}

.prompt-spinner-edit {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}

.prompt-dropdown-industries {
  background: linear-gradient(0deg, #f5f5f5, #f5f5f5),
    linear-gradient(0deg, #e5e5e5, #e5e5e5);
  border: 1px solid #e5e5e5 !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: -webkit-fill-available;
  font-size: 16px !important;
  padding: 12px 15px !important;
  color: #000 !important;
}

.prompt-detail-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 18px;
  box-shadow: 0px 5px 27px 0px rgba(199, 181, 199, 1);
  border-radius: 10px;
}

.prompt-collapse {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
}

.delete-document-button {
  background-color: red; /* Custom background color for this button */
  color: white; /* Optional: Set the text color */
}

.prompt-video-modal-container {
  flex-direction: column;
}

.prompt-video-button {
  background: none !important;
  border: 1px solid grey !important;
  margin-bottom: 5px;
  background-color: red !important;
}

.prompt-video-button-upload {
  background: none !important;
  border: 1px solid grey !important;
  margin-bottom: 5px;
  background-color: blue !important;
}

.prompt-video-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.prompt-collapse-btn {
  border: none;
  background: none;
}

.prompt-collapse-btn:not(:disabled):hover {
  color: #14a077;
}

.prompt-expand-btn {
  border-radius: 10%;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 42px;
  height: 38px;
  background-color: #14a077;
  color: #fff;
  text-align: start;
}

.prompt-divider {
  border-bottom: 1px solid lightgray;
}

.prompt-info-color {
  color: gray;
}

.recommended {
  color: black;
  padding-left: 5px;
}

.modal-text {
  margin-top: 16px;
  font-weight: 500;
  text-align: center;
}

.video-requirements {
  list-style-type: none;
  font-size: 14px;
}

.video-requirements li::before {
  content: '▶';
  margin-right: 8px;
}

.sub-text {
  font-size: 12pt;
  text-align: left;
  font-weight: normal;
}

.prompt-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #f8f9fa; /* Light background for a clean look */
  padding: 20px;
  border-radius: 10px; /* Soft rounded corners for the container */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.prompt-modal-content {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  gap: 20px; /* Add spacing between buttons */
}

.prompt-button-list {
  max-width: 250px !important; /* Limit button width for a more compact look */
  padding: 6px 10px; /* Larger padding for a more clickable button */
  font-size: 18px !important ; /* Larger font size for readability */
  font-weight: bold !important;
  color: white !important;
  background-color: #28a745; /* Green color for the buttons */
  border: none !important;
  border-radius: 8px !important; /* Rounded corners for a modern look */
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Initial shadow */
}

.prompt-button-list:hover {
  background-color: #218838; /* Darker shade on hover */
  transform: translateY(-2px) !important; /* Slight lift effect on hover */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2) !important; /* Increased shadow on hover */
}

.prompt-button-icon {
  margin-right: 10px !important;
  font-size: 24px !important; /* Larger icon size */
  vertical-align: middle !important;
}

.prompt-modal-heading {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.prompt-modal-item {
  padding: 10px 0;
}

.prompt-divider {
  border: none;
  border-top: 1px solid rgba(100, 102, 108, 0.49); /* Faint divider */
  margin: 10px 0; /* Space above and below the divider */
}

.prompt-container-list {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.prompt-container-list:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.prompt-text-container-list {
  display: flex;
  align-items: center;
}

.prompt-text-list {
  font-size: 16px;
  color: #333;
  font-weight: 500;
  cursor: pointer;
  margin: 0;
  padding: 0;
  transition: color 0.2s ease;
}

.prompt-text-list:hover {
  color: #218838;
}

.buttons-container-list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}



.edit-btn-list {
  background-color: #28a745 !important;
  border: none;
}

.edit-btn-list:hover {
  background-color: #218838 !important;
}

.delete-btn-list {
  background-color: #dc3545 !important;
  border: none;
}

.delete-btn-list:hover {
  background-color: #c82333 !important;
}

.prompt-detail-container-list {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.prompt-modal-btn,
.prompt-collapse-btn {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: block;
  width: 100%;
  text-align: center;
}

.prompt-modal-btn:hover {
  background-color: #218838;
  transform: translateY(-2px);
}

.prompt-modal-btn:disabled {
  background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3));
  color: light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3));
  border-color: light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3));

}

.modal-title {
  font-size: 12pt !important;
}

@media (min-width: 768px) {
  .prompt-modal-content {
    flex-direction: row !important; /* Align buttons horizontally on larger screens */
  }
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-container .tooltip-text {
  visibility: hidden;
  width: 180px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the button */
  left: 50%;
  margin-left: -90px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container .tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%; /* Bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.prompt-video-button-container.modern {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.modern-button {
  background-color: #28a745;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.modern-button:hover {
  background-color: #2d8f44;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.modern-text {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}

.modern-list {
  padding-left: 20px;
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.modern-file-info {
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width: 100%;
}

.modern-delete-button {
  border-radius: 50%;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modern-alert {
  margin-top: 20px;
  font-size: 14px;
}

.modern-submit-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.modern-submit-button:hover {
  background-color: #28a745;
}

.prompt-modal-container-video {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  gap: 40px;
}

video {
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  height: 60vh;
}

.btns-wrap-rec {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 100%;
}

.modern-button-rec {
  background-color: #28a745;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  color: white;
  font-size: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.modern-button-rec:hover {
  background-color: #28a745;
  transform: translateY(-2px);
}

.modern-button-rec:disabled {
  background-color: #6c757d;
}

.modern-button-rec + .modern-button-rec {
  margin-left: 10px;
}

.tips-container {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  max-width: 600px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  z-index: 1000; 
}

.tips-container h3 {
  margin-bottom: 15px;
  font-size: 24px;
}

.tips-container ul {
  list-style-type: disc;
  margin: 15px 0;
  padding-left: 20px;
  text-align: left;
}

.tips-container li {
  margin-bottom: 10px;
  font-size: 16px;
}

recommended-prompt {
  background-color: #f9f9f9;  /* Light background for the first two prompts */
  border: 1px solid #d0b23d;  /* Gold border to indicate it's recommended */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  /* Slight shadow for emphasis */
}

.star-icon {
  font-size: 14px;
  color: #d0b23d;  /* Gold star color */
  margin-left: 10px;
}

.modern-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 3px solid lightgray;
  background-color: #f8f9fa;
  border-radius: 10px 10px 0 0;
  font-weight: bold;
  font-size: 16pt;
}

/* General row styling for items */
.modern-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: white;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

/* Add hover effects to make rows feel interactive */
.modern-row:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

/* Styling for the prompt title section */
.prompt-title {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15pt;
  color: #333;
}

.play-icon {
  color: green;
  margin-right: 10px;
}

/* Styling for the action buttons (View and Delete) */
.action-buttons {
  display: flex;
  gap: 10px;
}

.modern-btn {
  background-color: #28a745;
  border: none;
  border-radius: 8px;
  padding: 8px 12px;
  color: white;
  font-size: 14px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.modern-btn:hover {
  background-color: #28a745;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.modern-btn:focus {
  outline: none;
}

/* Collapse styling to make it clean */
.modern-collapse {
  background-color: #f1f1f1;
  border-radius: 0 0 10px 10px;
  padding: 15px;
  margin-top: 10px;
}

/* Modal body styling */
.modern-modal-body {
  padding: 30px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Portfolio explanation text */
.portfolio-explanation {
  font-size: 14px;
  color: #666;
  margin-bottom: 30px;
}

/* Container for buttons */
.prompt-modal-container-portfolio {
  display: flex;
  justify-content: space-around;
  gap: 15px;
  flex-wrap: wrap;
}

/* Recommended button and star icon styling */
.recommended-button {
  position: relative;
  background-color: #28a745;
  font-weight: 600;
}

.recommended .star-icon {
  margin-right: 8px;
  color: gold;
  font-size: 18px;
}

/* Secondary button styling */
.modern-btn.variant-secondary {
  background-color: #6c757d;
}

.modern-btn.variant-secondary:hover {
  background-color: #495057;
}

