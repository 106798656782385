.reference-item {
  padding: 2rem 2rem;
  border: 1px solid rgba(0, 0, 0, 0.175);
  border-radius: 0.375rem;
}

.back-ref-icons {
  width: 25px;
  height: 100%;
}

.control-margin-reset {
  margin-bottom: 0px !important;
}
