.settings-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings-header-edit-btn {
  background-color: #14a077;
  border: none;
}

.settings-header-edit-btn:hover {
  background-color: #12bf8b;
}

.settings-header-edit-btn:active {
  background-color: #12bf8b !important;
}

.go-back-btn {
  background-color: transparent;
  border: none;
}

.typeahead .rbt-close .rbt-close-content {
  display: none;
}