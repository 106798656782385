/* CompleteProfile.css */
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Use a modern font */
  background-color: #f8f9fa; /* Light background for a clean look */
  color: #212529;
}

.container {
  max-width: 900px; /* Limit container width for better readability */
  margin: auto;
}

.card {
  border-radius: 10px; /* Softer corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border: none; /* Remove border for a cleaner look */
  margin-bottom: 30px; /* Consistent spacing between cards */
}

.card-header {
  background-color: rgb(26 138 52) !important; /* Primary color for header */
  color: #fff !important; /* White text for contrast */
  border-bottom: none !important; /* Remove border */
  text-transform: uppercase !important; /* Modern touch */
  font-size: 1.2rem !important;
  font-weight: 700;
  padding: 1rem !important;
  cursor: pointer; 
  user-select: none;
}

.card-body {
  padding: 1.5rem !important; /* Increase padding for more space */
}

.form-label {
  font-weight: 500; /* Slightly bolder labels */
  color: #495057;
  margin-bottom: 0.5rem;
}

.form-control,
.form-select,
.phone-input .form-control {
  height: calc(1.5em + 0.75rem + 2px); /* Consistent height across all form elements */
  padding: 0.75rem; /* Ensure consistent padding */
  font-size: 1rem;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #ced4da; /* Subtle border */
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.phone-input {
  width: 100%;
}

.phone-input .form-control {
  width: 100%;
  border-radius: 8px;
  padding-left: 3rem;
}

.form-control:focus {
  border-color: rgb(26 138 52);
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); /* Green shadow on focus */
}

.typeahead {
  width: 100%;
}

.typeahead input {
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: 8px;
}


.d-grid .btn-success {
  width: auto; /* Makes the button width fit the text content */
  padding: 0.5rem 1.5rem; /* Adjust the padding for a smaller button */
  font-size: 1rem; /* Slightly smaller font size */
  font-weight: 700;
  display: inline-block; /* Ensure it doesn't stretch to full width */
  margin-left: auto; /* Pushes the button to the right */
  margin-right: 0; /* Removes any right margin */
}

.btn-success:hover {
  background-color: #218838 !important;
  transform: translateY(-2px) !important; /* Subtle lift on hover */
}

.alert {
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1rem;
}

.prompt-spinner {
  text-align: center;
  margin-top: 2rem;
}

h1 {
  font-size: 2rem;
  font-weight: bold;
  color: #343a40;
}

@media (max-width: 768px) {
  .container {
    padding: 0 1rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  .btn-success {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }

  .phone-input .form-control {
    padding-right: 1rem; /* Adjust padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .phone-input .form-control {
    padding-right: 0.5rem; /* Further adjust padding for very small screens */
  }
}
