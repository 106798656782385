.portfolio-control-content {
  width: 100%;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.175);
  border-radius: 0.375rem;
}

.portfolio-control-content>p {
  margin-bottom: 50px;
}

.switch-form {
  max-width: 600px;
  margin: 0 auto 80px auto;
}

.switch-form .form-check-label {
  flex-grow: 1; /* Ensure label takes up remaining space */
  word-wrap: break-word; /* Ensure long words wrap within the container */
  padding-right: 10px; /* Add padding so the text doesn't touch the switch */
}

.portfolio-control-button {
  font-weight: 600;
  box-shadow: rgb(38, 57, 77) 0px 5px 10px 0px;
}

.control-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.control-btn-wrapper>button {
  width: 100px;
}

.form-check-input:checked {
  background-color: #15734785;
  border-color: #15734785;
}

.select-all-toggle {
  margin-left: auto;
}

@media (max-width: 768px) {
  /* For small screens (mobile), stack the "Select All" toggle and text */
  .portfolio-control-content .d-flex {
    flex-direction: column;
    align-items: flex-start;
  }

  .select-all-toggle {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .switch-form .form-check {
    flex-direction: row;
    width: 100%; /* Ensure the form check takes the full width */
  }

  .control-btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .portfolio-control-button {
    width: 100%;
  }
}

@media (min-width: 769px) {
  .control-btn-wrapper {
    justify-content: flex-end;
  }
}
